* {
  /* box-sizing: border-box;
  margin: 0; */
  /* font-family: "Roboto Slab", serif; */
}

html {
  scroll-behavior: smooth;
}

/* carousel */
.carousel .slide {
  background-color: white !important;
}

.control-dots {
  bottom: 40px !important;
}
